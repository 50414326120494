<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
             label-width="80px">
      <el-form-item label="微信用户名" prop="uname">
        <el-input v-model="dataForm.uname" placeholder="微信用户名"></el-input>
      </el-form-item>
      <el-form-item label="电话，需要根据这个来识别人物，要不然根据uid和uname不能来识别" prop="phone">
        <el-input v-model="dataForm.phone" placeholder="电话，需要根据这个来识别人物，要不然根据uid和uname不能来识别"></el-input>
      </el-form-item>
      <el-form-item label="" prop="raw">
        <el-input v-model="dataForm.raw" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="" prop="updateTime">
        <el-input v-model="dataForm.updateTime" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import AddOrUpdateMixin from "@/mixins/AddOrUpdate";

export default {
  mixins: [AddOrUpdateMixin],
  data() {
    return {
      dataRule: {
        uname: [
          {required: true, message: '微信用户名不能为空', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '电话，需要根据这个来识别人物，要不然根据uid和uname不能来识别不能为空', trigger: 'blur'}
        ],
        raw: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ],
        updateTime: [
          {required: true, message: '不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(id) {
      this.doInit(id, `/wechat/wechatuser/info`, 'wechatUser').then((resp)=> {
        this.dataForm.updateTime = this.$utils.dateFormat('yyyy-MM-dd hh:mm', resp.updateTime)
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.doSubmit(`/wechat/wechatuser/${!this.dataForm.uid ? 'save' : 'update'}`, {
        'uid': this.dataForm.uid || undefined,
        'uname': this.dataForm.uname,
        'phone': this.dataForm.phone,
        'raw': this.dataForm.raw
      })
    }
  }
}
</script>
